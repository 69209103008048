import React from "react";

const LegalPopup = ({open, data, handlePopup}) => {

    if (typeof window !== 'undefined' && open) {
        document.body.classList.add('overflow--hidden');
    }

    const closePopup = () => {
        document.body.classList.remove('overflow--hidden');
        handlePopup(false)
    }

    return (
        <>
            { open ? (
                <span
                    role="button"
                    onKeyDown={closePopup}
                    onClick={closePopup}
                    tabIndex={0}
                    aria-label="Close Popup."
                    className="overlay"/>
            ) : ''}
            <div id="popup" className="popup popup-legal" style={ open ? { display: 'block'} : { display: 'none'}} >
                <button onClick={closePopup} className="popup__close"><i className="fal fa-times"/></button>

                <h3 className="txt--left mb--30">{data.title}</h3>
                <div className="popup__content" dangerouslySetInnerHTML={{ __html: data.content }}/>
            </div>
        </>
    )
}
export default LegalPopup
