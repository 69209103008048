import React from "react"
import {useStaticQuery, graphql} from "gatsby";

export const UseShopifyDataQuery = () => {

    let shopifyList = []

    const shopify = useStaticQuery(graphql`
     {
        allShopifyProduct(sort: {fields: [title]}) {
            edges {
              node {
                title
                images {
                  originalSrc
                }
                shopifyId
                description
                availableForSale
                priceRange {
                  maxVariantPrice {
                    amount
                  }
                  minVariantPrice {
                    amount
                  }
                }
                createdAt
                handle
                variants {
                  compareAtPrice
                  id
                  price
                }
              }
            }
        }
    }`);

    shopify.allShopifyProduct.edges.map(({ node }) => {
        shopifyList = [...shopifyList, node]
    })

    return shopifyList;
}

