import React, {useEffect, useState} from "react";
import { Link } from "gatsby";
import Country from "./Country";
import { slideUp, slideToggle} from "../utilities/slide";
import ThemeContext  from "../context/ThemeContext";

const Header = ({handleCountry}) => {

    const [menuOverlay, setMenuOverlay] = useState(false)

    const [country, setCountry] = useState('US')

    const [isJumpMenu, setJumpMenu] = useState(false)

    const [scrolled, setScrolled] = useState(false)


    const handleScroll = () => {
        const offset = window.scrollY;
        const header = document.querySelector('.header')
        const headerHeight = header.clientHeight

        const preheader = document.querySelector('.preheader')
        const preheaderHeight = preheader ? preheader.clientHeight : 0

        if(offset > preheaderHeight) {
            if(preheader) {
                document.body.style.paddingTop = headerHeight + 'px';
            }
            setScrolled(true)
        } else {
            if(preheader) {
                document.body.style.paddingTop = 0;
            }
            setScrolled(false)
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll)
        window.addEventListener('resize', handleScroll)
        setJumpMenu(document.querySelectorAll('.responsive-menu-btn').length)
    })


    useEffect(()=>{
        if(!menuOverlay) {
            document.body.classList.remove('menu-open')
        }
    },[menuOverlay])

    useEffect(()=>{
        setCountry(window.localStorage.getItem("country"))
    },[])

    const handleMenu = () => {
        document.body.classList.toggle('menu-open')

        document.body.classList.contains('menu-open') ? setMenuOverlay(true) : setMenuOverlay(false)
    }

    const handleMenuDropdown = (e) => {
        e.preventDefault()

        const menuItemDropdown = document.querySelectorAll('.menu-item--has-dropdown');
        let menuItemLi = e.target.parentNode;

        if(window.innerWidth <= 991 ) {
            [].filter.call(menuItemDropdown, (elem) => {

                if(elem !== menuItemLi) {
                    slideUp(elem.querySelector('.menu-dropdown-toggeable'), elem);
                    elem.classList.remove('open');
                } else {
                    slideToggle(menuItemLi.querySelector('.menu-dropdown-toggeable'), elem);
                    menuItemLi.classList.toggle('open');
                }
            });
        }
    }

    const handleMenuNestedDropdown = (e) => {
        e.preventDefault();
        const menuItemDropdown = document.querySelectorAll('.menu-dropdown__submenu');
        let menuItemLi = e.target.parentNode;

        if(window.innerWidth <= 991 ) {
            [].filter.call(menuItemDropdown, (elem) => {

                if(elem !== menuItemLi) {
                    slideUp(elem.querySelector('.menu-submenu-dropdown'), elem);
                    elem.classList.remove('open');
                } else {
                    slideToggle(menuItemLi.querySelector('.menu-submenu-dropdown'), elem);
                    menuItemLi.classList.toggle('open');
                }
            });
        }

    }

    const closeSidebar = () => {
        if(window.innerWidth <= 991) {
            document.body.classList.remove('menu-open')
            setMenuOverlay(false)
        }
    }

    return (
        <ThemeContext.Consumer>
            {theme => (
                <>
                {(menuOverlay) ? (
                    <span
                        aria-label="Overlay."
                        role="button"
                        tabIndex="0"
                        onClick={handleMenu}
                        onKeyDown={handleMenu}
                        className="overlay"/>
                ) : ''}
                <header className={`header ${ scrolled ? 'sticky' : '' }`}>
                    <div className="container d-flex justify-content-between align-items-center">
                        <div className="header--left">
                            <button className="menu-toggle d-lg-none" onClick={handleMenu}>
                                <span/>
                                <span/>
                                <span/>
                            </button>
                            <Link  onClick={closeSidebar} to="/" className="logo"><img className="shadow-0" src="/images/logo.svg" alt="Logo" width="216" height="49"/></Link>
                        </div>
                        <nav className="menu-nav">

                            <div className="d-flex d-lg-none">
                                <Country
                                    handleCountry={handleCountry}>
                                </Country>
                            </div>

                            <ul className={`menu ${isJumpMenu ? 'menu--padding' : ''}`}>
                                <li className="menu-item menu-item--has-dropdown">
                                    <div role="button" tabIndex={-1} onClick={handleMenuDropdown}>
                                        <Link className="menu-item__btn" onClick={closeSidebar} to="/category/programs/" activeClassName="active">programs</Link>
                                    </div>
                                    <div className="menu-dropdown-toggeable">
                                        <ul className="menu-dropdown pr--0">
                                            <li className="menu-item menu-dropdown__submenu">
                                                <div role="button" tabIndex={-1} onClick={handleMenuNestedDropdown}>
                                                    <Link className="menu-item__btn" onClick={closeSidebar} to="/category/workout-plans/" activeClassName="active">Workout plans</Link>
                                                </div>
                                                <ul className="menu-submenu-dropdown">
                                                    <li className="menu-item"><Link onClick={closeSidebar} to="/products/90-day-challenge" activeClassName="active">90 day challenge</Link></li>
                                                    <li className="menu-item"><Link onClick={closeSidebar} to="/products/home-workout-domination" activeClassName="active">Home Workout Domination</Link></li>
                                                    <li className="menu-item"><Link onClick={closeSidebar} to="/products/30-day-booty-abs-challenge" activeClassName="active">Booty and Abs Challenge</Link></li>
                                                    <li className="menu-item"><Link onClick={closeSidebar} to="/products/lioness" activeClassName="active">Lioness</Link></li>
                                                </ul>
                                            </li>
                                            <li className="menu-item menu-dropdown__submenu">
                                                <div role="button" tabIndex={-1} onClick={handleMenuNestedDropdown}>
                                                    <Link className="menu-item__btn" onClick={closeSidebar} to="/category/meal-plans/" activeClassName="active">Meal plans</Link>
                                                </div>
                                                <ul className="menu-submenu-dropdown">
                                                    <li className="menu-item"><Link onClick={closeSidebar} to="/products/body-fuel-system" activeClassName="active">Body Fuel System</Link></li>
                                                    <li className="menu-item"><Link onClick={closeSidebar} to="/products/30-day-meal-plan" activeClassName="active">30 day meal plan</Link></li>
                                                    <li className="menu-item"><Link onClick={closeSidebar} to="/products/7-day-meal-plan" activeClassName="active">7 day meal plan</Link></li>
                                                </ul>
                                            </li>
                                            <li className="menu-item"><a href="https://shop.thebettyrocker.com/rock-your-life/" target="_blank" rel="noopener">Rock Your Life</a></li>
                                        </ul>
                                    </div>

                                </li>

                                { (country === 'US') ? (
                                <li className="menu-item menu-item--has-dropdown">
                                    <div role="button" tabIndex={-1} onClick={handleMenuDropdown}>
                                        <Link className="menu-item__btn" onClick={closeSidebar} to="/category/supplements/" activeClassName="active">supplements</Link>
                                    </div>
                                    <div className="menu-dropdown-toggeable">
                                        <ul className="menu-dropdown">
                                            <li className="menu-item"><Link onClick={closeSidebar} to="/products/vanilla-protein" activeClassName="active">vanilla protein</Link></li>
                                            <li className="menu-item"><Link onClick={closeSidebar} to="/products/berry-green-protein" activeClassName="active">berry green protein</Link></li>
                                            <li className="menu-item"><Link onClick={closeSidebar} to="/products/rock-and-restore" activeClassName="active">rock and restore</Link></li>
                                            <li className="menu-item"><Link onClick={closeSidebar} to="/products/full-body-collagen" activeClassName="active">full body collagen</Link></li>
                                            <li className="menu-item"><Link onClick={closeSidebar} to="/products/serenicalm" activeClassName="active">serenicalm</Link></li>
                                            <li className="menu-item"><Link onClick={closeSidebar} to="/products/whole-sleep" activeClassName="active">whole sleep</Link></li>
                                        </ul>
                                    </div>
                                </li>
                                ) : ''}
                                <li className="menu-item menu-item--has-dropdown">
                                    <button className="menu-item__btn" onClick={handleMenuDropdown}>about</button>
                                    <div className="menu-dropdown-toggeable">
                                        <ul className="menu-dropdown">
                                            <li className="menu-item"><a onClick={closeSidebar} href="https://thebettyrocker.com/about/">the betty rocker</a></li>
                                            <li className="menu-item"><a onClick={closeSidebar} href="https://thebettyrocker.com/about-whole-betty/">whole betty</a></li>
                                        </ul>
                                    </div>
                                </li>
                                <li className="menu-item menu-item--has-dropdown">
                                    <button className="menu-item__btn" onClick={handleMenuDropdown}>reviews</button>
                                    <div className="menu-dropdown-toggeable">
                                        <ul className="menu-dropdown">
                                            <li className="menu-item"><a onClick={closeSidebar} href="https://thebettyrocker.com/success-stories/">betty rocker programs</a></li>
                                            <li className="menu-item"><a onClick={closeSidebar} href="https://thebettyrocker.com/supplement-reviews/">whole betty supplements</a></li>
                                        </ul>
                                    </div>
                                </li>
                                <li className="menu-item"><a className="menu-item__btn" onClick={closeSidebar} href="https://thebettyrocker.com/blog/">blog</a></li>
                                <li className="menu-item"><a className="menu-item__btn" onClick={closeSidebar} href="https://thebettyrocker.com/faq/">faq</a></li>
                                <li className="menu-item d-block d-lg-none"><a className="menu-item__btn" onClick={closeSidebar} href="https://thebettyrocker.com/contact/">Contact</a></li>
                            </ul>
                        </nav>
                        <div className="header__buttons">
                            <a href="https://store.thebettyrocker.com/account/login/" onClick={closeSidebar} className="header__btn test"><i className="fas fa-user" aria-label="My Account"/></a>
                            <button onClick={theme.handleSidebar} className="header__btn header__btn--lg header__btn--pink">
                                <i className="far fa-shopping-basket"/> <span>{theme.cart ? theme.cart.length : 0}</span>
                            </button>
                        </div>
                    </div>
                </header>
                </>
                )}
        </ThemeContext.Consumer>
    )
}
export default Header
