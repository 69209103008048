import React, {useEffect, useState} from "react";

const Country = ({handleCountry}) => {

    const [country, setCountry] = useState('US')

    useEffect(()=>{
        setCountry(window.localStorage.getItem("country"));
    },[])

    return (
        <div className="preheader__tabs">
            <button onClick={handleCountry} className={`preheader__tab ${ (country === 'US') ? 'active' : '' }`}>
                <img src="/images/usa-icon.png" width="15" height="15" alt=""/><span>USA</span>
            </button>
            <button onClick={handleCountry} className={`preheader__tab ${ (country === 'worldwide') ? 'active' : '' }`}>
                <img src="/images/worldwide-icon.png" width="15" height="15" alt=""/><span>WORLDWIDE</span>
            </button>
        </div>
    )
}
export default Country
