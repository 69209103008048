/*
*  Shopify Variant IDs:
*
* 7DMP: Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zOTc5MDEyOTkwNTg1Ng==
* 30DMP: Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zOTc5MDY5NTY3ODE0NA==
* BFS: Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zOTc5MDczNjE0NjYyNA==
* 90DCH: Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zOTc5MDc2NjkxNTc3Ng==
* HWD: Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zOTc5MDc4Njc0MDQxNg==
* AABCH: Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zOTc5MDgyMzc2ODI1Ng==
* Lioness: Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zOTc5MDgxMDMwMDYwOA==
* I love Vanilla Protein: Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zNzc1MTcxMjQxNTkzNg==
* Berry Green Protein: Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8yMDgwODcwNzg2NjY5NA==
* Full Body Collagen: Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zMTI3NTI4MjM5OTMwMg==
* Rock And Restore: Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC80MDE0NzU1NzY0NjUyOA==
* Whole Sleep: Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zMTQzMTcwMDU0NTYwNg==
* Serenicalm: Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zMjA4NDM0OTE1NzQ0Ng==
*
* */

const suggestedProducts = {
    // 7dmp
    "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zOTc5MDEyOTkwNTg1Ng==": [
        "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zNzc1MTcxMjQxNTkzNg==", // I love Vanilla Protein
        "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zOTc5MDczNjE0NjYyNA==" // Body Fuel System
    ],
    // 30dmp
    "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zOTc5MDY5NTY3ODE0NA==": [
        "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zOTc5MDgyMzc2ODI1Ng==", // AABCH
        "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zNzc1MTcxMjQxNTkzNg==" // I love Vanilla Protein
    ],
    // BFS
    "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zOTc5MDczNjE0NjYyNA==": [
        "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zOTc5MDc4Njc0MDQxNg==", // HWD
        "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zNzc1MTcxMjQxNTkzNg==" // I love Vanilla Protein
    ],
    // 90DCH
    "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zOTc5MDc2NjkxNTc3Ng==": [
        "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zNzc1MTcxMjQxNTkzNg==", // I love Vanilla Protein
        "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8yMDgwODcwNzg2NjY5NA==" // Berry Green Protein
    ],
    // HWD
    "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zOTc5MDc4Njc0MDQxNg==": [
        "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zOTc5MDczNjE0NjYyNA==", // Body Fuel System
        "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zNzc1MTcxMjQxNTkzNg==" // I love Vanilla Protein
    ],
    // AABCH
    "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zOTc5MDgyMzc2ODI1Ng==": [
        "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zOTc5MDY5NTY3ODE0NA==", // 30dmp
        "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zNzc1MTcxMjQxNTkzNg==" // I love Vanilla Protein
    ],
    // Lioness:
    "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zOTc5MDgxMDMwMDYwOA==": [
        "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zNzc1MTcxMjQxNTkzNg==", // I love Vanilla Protein
        "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC80MDE0NzU1NzY0NjUyOA==" // Rock and Restore
    ],
    // I love Vanilla Protein
    "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zNzc1MTcxMjQxNTkzNg==": [
        "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8yMDgwODcwNzg2NjY5NA==", // Berry Green Protein
        "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zOTc5MDczNjE0NjYyNA==" // Body Fuel System
    ],
    // Berry Green Protein
    "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8yMDgwODcwNzg2NjY5NA==" : [
        "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zNzc1MTcxMjQxNTkzNg==", // I love Vanilla Protein
        "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zOTc5MDczNjE0NjYyNA==" // Body Fuel System
    ],
    // Full Body Collagen
    "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zMTI3NTI4MjM5OTMwMg==" : [
        "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zNzc1MTcxMjQxNTkzNg==", // I love Vanilla Protein
        "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zOTc5MDczNjE0NjYyNA==" // Body Fuel System
    ],
    // Rock and Restore
    "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC80MDE0NzU1NzY0NjUyOA==": [
        "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zNzc1MTcxMjQxNTkzNg==", // I love Vanilla Protein
        "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zOTc5MDczNjE0NjYyNA==" // Body Fuel System
    ],
    // Whole Sleep
    "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zMTQzMTcwMDU0NTYwNg==": [
        "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zNzc1MTcxMjQxNTkzNg==", // I love Vanilla Protein
        "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC80MDE0NzU1NzY0NjUyOA==" // Rock and Restore
    ],
    // Serenicalm
    "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zMjA4NDM0OTE1NzQ0Ng==": [
        "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zNzc1MTcxMjQxNTkzNg==", // I love Vanilla Protein
        "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC80MDE0NzU1NzY0NjUyOA==" // Rock and Restore
    ]

}

const suggestedProductsWW = {
    // 7dmp
    "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zOTc5MDEyOTkwNTg1Ng==": [
        "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zOTc5MDczNjE0NjYyNA==" // BFS
    ],
    // 30DMP
    "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zOTc5MDY5NTY3ODE0NA==": [
        "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zOTc5MDgyMzc2ODI1Ng==" // AABCH
    ],
    // BFS
    "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zOTc5MDczNjE0NjYyNA==": [
        "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zOTc5MDc4Njc0MDQxNg==" // HWD
    ],
    // HWD
    "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zOTc5MDc4Njc0MDQxNg==": [
        "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zOTc5MDczNjE0NjYyNA==" // BFS
    ],
    // AABCH
    "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zOTc5MDgyMzc2ODI1Ng==": [
        "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zOTc5MDY5NTY3ODE0NA==" // 30DMP
    ]

}

export {suggestedProducts, suggestedProductsWW}
