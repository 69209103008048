import React, {useEffect, useState} from "react";
import {Link} from "gatsby"
import legalData from "../../static/data/legal"
import LegalPopup from "./LegalPopup";

export default function Footer({title, handleCountry}) {

    const isPadding = (title === 'The Betty Rocker' || title === 'Workout Plans' || title === 'Meal Plans' || title === 'Supplements' || title === 'Programs')

    const [popupOpen, setPopupOpen] = useState(false)
    const [popupData, setPopupData] = useState([])

    const [country, setCountry] = useState('US');

    useEffect(()=>{
        setCountry(window.localStorage.getItem("country"))
    },[])

    const handlePopupData = (type) => {
        setPopupData(legalData[type])
        handlePopup(true)
    }

    const handlePopup = (isOpen) => {
        setPopupOpen(isOpen)
    }

    return (
        <>
            <footer className={`footer ${ isPadding ? 'footer--padding' : '' }`}>
                <div className="container">
                    <div className="row gutters-30 align-items-center">
                        <div className="col-12 col-sm-6 order-1 order-sm-3 order-lg-1 col-lg-3">
                            <h6>Connect with me</h6>
                            <div className="social-media">
                                <div className="d-flex justify-content-around">
                                    <a rel="nofollow noopener noreferrer" href="https://www.facebook.com/thebettyrocker" target="_blank">
                                        <i className="fab fa-facebook-f" aria-hidden="true"/><span className="sr-only hidden">Facebook</span>
                                    </a>
                                    <a rel="nofollow noopener noreferrer" href="https://www.instagram.com/thebettyrocker/" target="_blank">
                                        <i className="fab fa-instagram" aria-hidden="true"/><span className="sr-only hidden">Instagram</span>
                                    </a>
                                    <a rel="nofollow noopener noreferrer" href="https://www.pinterest.com/thebettyrocker/" target="_blank">
                                        <i className="fab fa-pinterest" aria-hidden="true"/><span className="sr-only hidden">Pinterest</span>
                                    </a>
                                    <a rel="nofollow noopener noreferrer" href="https://www.youtube.com/user/TheBettyRocker" target="_blank">
                                        <i className="fab fa-youtube" aria-hidden="true"/><span className="sr-only hidden">Youtube</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 order-2 order-sm-1 order-lg-2 col-lg-3">
                            <ul className="footer-nav">
                                <li><a target="_blank" rel="nofollow noopener noreferrer" href="https://thebettyrocker.com/blog/">Blog</a></li>
                                <li><a target="_blank" rel="nofollow noopener noreferrer" href="https://thebettyrocker.com/faq/">FAQ</a></li>
                                <li><a target="_blank" rel="nofollow noopener noreferrer" href="https://thebettyrocker.com/success-stories/">Reviews</a></li>
                                <li><a target="_blank" rel="nofollow noopener noreferrer" href="https://thebettyrocker.com/contact/">Contact Us</a></li>
                            </ul>
                        </div>
                        <div className="col-12 col-sm-6 order-3 order-sm-2 order-lg-3 col-lg-3">
                            <ul className="footer-nav">
                                <li><Link to="/category/workout-plans/">Workout Plans</Link></li>
                                <li><Link to="/category/meal-plans/">Meal Plans</Link></li>
                                <li><a target="_blank" rel="nofollow noopener noreferrer" href="https://shop.thebettyrocker.com/rock-your-life/">Rock Your Life</a></li>
                                {country === 'US' && <li><Link to="/category/supplements/">Supplements</Link></li>}
                                <li><a target="_blank" rel="nofollow noopener noreferrer" href="https://represent.com/store/betty-rocker-apparel/">Apparel</a></li>
                            </ul>
                        </div>
                        <div className="col-12 col-sm-6 order-4 order-sm-4 order-lg-4 col-lg-3">
                            <h6 className="txt--22">{ country === "US" ? 'Not in the states?' : 'In the states?' }</h6>
                            <span className="double-arrows"/>
                            <button onClick={handleCountry} className="btn btn--full">{ country === "US" ? 'worldwide shop' : 'us shop'}</button>
                        </div>
                    </div>

                    <ul className="footer-aftermenu">
                        <li><span>© {(new Date().getFullYear())} The Betty Rocker, Inc. </span></li>
                        <li><span>All Rights Reserved</span></li>
                        <li><button className="footer-link" onClick={() => handlePopupData('terms')}>Terms</button></li>
                        <li><button className="footer-link" onClick={() => handlePopupData('privacy')}>Privacy</button></li>
                        <li><button className="footer-link" onClick={() => handlePopupData('return')}>Return Policy</button></li>
                        <li><button className="footer-link" onClick={() => handlePopupData('shipping')}>Shipping Information</button></li>
                    </ul>
                </div>
            </footer>

            {popupOpen &&
            <LegalPopup
                open={popupOpen}
                data={popupData}
                handlePopup={handlePopup}
            />}
        </>
    )
}
