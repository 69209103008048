import React, {useEffect, useState} from "react";
import ThemeContext from "../context/ThemeContext";
import {suggestedProducts, suggestedProductsWW} from "../../static/data/suggestedProducts";
import productData from "../../static/data/product";

const SidebarRecommended = ({checkout, shopifyData}) => {

    const [suggestedProductsItems, setSuggestedProductsItems] = useState([]);

    useEffect(()=>{

        let country = window.localStorage.getItem("country");

        let checkoutItemsIDs = []
        checkout.map((item) => {
            checkoutItemsIDs = [...checkoutItemsIDs, item.id]
        })

        setSuggestedProductsItems([])
        let suggestedProductsIDs = []

        // if there is only one item in the cart, show both of the recommended products for that item
        if(checkoutItemsIDs.length === 1) {

            if(country.toLowerCase() === 'us') {
                suggestedProductsIDs = [...suggestedProductsIDs, suggestedProducts[checkoutItemsIDs][0], suggestedProducts[checkoutItemsIDs][1]]
            } else {
                if(suggestedProductsWW[checkoutItemsIDs]) {
                    suggestedProductsIDs = [...suggestedProductsIDs, suggestedProductsWW[checkoutItemsIDs][0]]
                }
            }
        } else {

            if(country.toLowerCase() === 'us') {
                //for the first item in the suggestedProducts
                // if the first recommended item is not in the cart, add it's not already in the suggestedProducts, add it to the suggestedProducts and break the loop
                // if the first recommended item is already in the cart or in the recommended items, check if the second is, and if it's not, add the second item to the suggestedProducts and break the loop
                for(let i = 0; i < checkoutItemsIDs.length; i++) {
                    if(!(checkoutItemsIDs.includes(suggestedProducts[checkoutItemsIDs[i]][0]) || suggestedProductsIDs.includes(suggestedProducts[checkoutItemsIDs[i]][0]))) {
                        suggestedProductsIDs = [...suggestedProductsIDs, suggestedProducts[checkoutItemsIDs[i]][0]];
                        break;
                    } else {
                        if(!(checkoutItemsIDs.includes(suggestedProducts[checkoutItemsIDs[i]][1]) || suggestedProductsIDs.includes(suggestedProducts[checkoutItemsIDs[i]][1]))) {
                            suggestedProductsIDs = [...suggestedProductsIDs, suggestedProducts[checkoutItemsIDs[i]][1]];
                            break;
                        }
                    }
                }
                //for the second item in the suggestedProducts
                // if the first recommended item is not in the cart, add it's not already in the suggestedProducts, add it and break the loop
                // if the first recommended item is already in the cart or in the recommended items, check the second item and if it's not in the cart or suggested items, add it and break the loop

                for(let i = 1; i < checkoutItemsIDs.length; i++) {
                    if(!(checkoutItemsIDs.includes(suggestedProducts[checkoutItemsIDs[i]][0]) || suggestedProductsIDs.includes(suggestedProducts[checkoutItemsIDs[i]][0]))) {
                        suggestedProductsIDs = [...suggestedProductsIDs, suggestedProducts[checkoutItemsIDs[i]][0]];
                        break;
                    } else {
                        if(!(checkoutItemsIDs.includes(suggestedProducts[checkoutItemsIDs[i]][1]) || suggestedProductsIDs.includes(suggestedProducts[checkoutItemsIDs[i]][1]))) {
                            suggestedProductsIDs = [...suggestedProductsIDs, suggestedProducts[checkoutItemsIDs[i]][1]];
                            break;
                        }
                    }
                }
            } else {

                //for the first item in the suggestedProducts
                // if the first recommended item is not in the cart, add it's not already in the suggestedProducts, add it to the suggestedProducts and break the loop
                for(let i = 0; i < checkoutItemsIDs.length; i++) {
                    if(suggestedProductsWW[checkoutItemsIDs[i]] && !(checkoutItemsIDs.includes(suggestedProductsWW[checkoutItemsIDs[i]][0]) || suggestedProductsIDs.includes(suggestedProductsWW[checkoutItemsIDs[i]][0]))) {
                        suggestedProductsIDs = [...suggestedProductsIDs, suggestedProductsWW[checkoutItemsIDs[i]][0]];
                        break;
                    }
                }
                //for the second item in the suggestedProducts
                // if the first recommended item is not in the cart, add it's not already in the suggestedProducts, add it and break the loop
                for(let i = 1; i < checkoutItemsIDs.length; i++) {
                    if(suggestedProductsWW[checkoutItemsIDs[i]] && !(checkoutItemsIDs.includes(suggestedProductsWW[checkoutItemsIDs[i]][0]) || suggestedProductsIDs.includes(suggestedProductsWW[checkoutItemsIDs[i]][0]))) {
                        suggestedProductsIDs = [...suggestedProductsIDs, suggestedProductsWW[checkoutItemsIDs[i]][0]];
                        break;
                    }
                }

            }

        }
        setSuggestedProductsItems(suggestedProductsIDs)

    },[checkout])

    return (
        <ThemeContext.Consumer>
            {theme => (
                <>
                    {!!suggestedProductsItems.length &&
                    <div className="sidebar__recommended">
                        <h6 className="sidebar__recommended__title"><i className="fas fa-heart txt--pink"/> you might also like</h6>
                        <div className="row gutters-10">
                            {
                                suggestedProductsItems.map((suggestedProduct) => (
                                    shopifyData.map((shopifyProduct, index) => (
                                        <>
                                            { shopifyProduct.shopifyId === productData[suggestedProduct].shopifyId &&
                                            <div key={index} className="col-6">
                                                <div className="recommended-item">
                                                    <div className="recommended-item__img">
                                                        <img src={shopifyProduct.images[0].originalSrc} alt="" className="no-shadow"/>
                                                    </div>
                                                    { shopifyProduct.title === 'I ❤ Vanilla Protein' ?
                                                    <h6 className="recommended-item__title">
                                                        I <i className='fas fa-heart txt--pink'/> Vanilla Protein
                                                    </h6> :
                                                    <h6 className="recommended-item__title">
                                                        {shopifyProduct.title}
                                                    </h6>
                                                    }
                                                    <p className="recommended-item__price">${shopifyProduct.variants[0].price}</p>

                                                    <button className="btn btn--outline" onClick={() => theme.addVariantToCart(shopifyProduct.variants[0].id, 1, shopifyProduct.variants[0].price, shopifyProduct.title)}>Add to Cart</button>
                                                </div>
                                            </div>
                                            }
                                        </>

                                    ))

                                ))

                            }
                        </div>
                    </div>}
                </>
            )}
        </ThemeContext.Consumer>
    )
}
export default SidebarRecommended
