import React from "react";
import Country from "./Country";

const Preheader = ({handleCountry}) => {
    return (
        <div className="preheader">
            <div
                className="container d-flex justify-content-center justify-content-lg-between align-items-center">
                <div className="preheader__notice">
                    {/*{ (country === 'US') ? (*/}
                    {/*<p><i className="fas fa-truck"></i>FREE shipping on orders $85 and over <span*/}
                    {/*    className="d-inline-block">within the continental US!</span></p>*/}
                    {/*) : '' }*/}
                </div>
                <nav className="preheader__nav d-none d-lg-flex">
                    <a href="https://thebettyrocker.com/contact/" className="preheader__nav__item">Contact</a>

                    <Country
                    handleCountry={handleCountry}/>
                </nav>
            </div>
        </div>
    )
}
export default Preheader
