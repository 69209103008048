import React, {useEffect, useState} from "react";
import productData from "../../static/data/product";

export default function CartItem({product, item, removeLineItemInCart, updateQuantityInCart}) {
    const [count, setCount] = useState(item.quantity)
    const [title, setTitle] = useState("")
    const [subscriptionMessage, setSubscriptionMessage] = useState('');
    const icon = [
        (productData[item.id].type.toLowerCase() === 'workout plan' && "flex") ||
        (productData[item.id].type.toLowerCase() === 'meal plan' && "utensils") ||
        (productData[item.id].type.toLowerCase() === 'supplements' && "leaf")
    ]

    useEffect(() => {
        if (product.title === 'I ❤ Vanilla Protein') {
            setTitle("I <i class='fas fa-heart txt--pink'></i> Vanilla Protein")
        } else {
            setTitle(product.title)
        }
        setCount(item.quantity)

    },[product.title, item.quantity])

    const stepDown = () => {
        let newCount = +count - 1;
        if(count - 1 >= 0) {
            setCount(newCount)
            updateQuantityInCart(item.id, newCount, item.selling_plan, true);
        }
    }
    const stepUp = () => {
        let newCount = +count + 1;
        setCount(newCount)
        updateQuantityInCart(item.id, newCount, item.selling_plan, true);
    }

    // update product's quantity value
    useEffect(() => {
        setCount(item.quantity)
    },[item.quantity])

    useEffect(()=>{
        if(productData[item.id].sellingPlans) {
            productData[item.id].sellingPlans.map((plan) => {
                if(Number(item.selling_plan) === Number(plan.sellingPlanId)) {

                    if(productData[item.id].type === 'supplements') {
                        setSubscriptionMessage(`Subscribed - ${plan.sellingPlanName}`)
                    } else {
                        setSubscriptionMessage(plan.sellingPlanName)
                    }

                }
            })
        }
    },[item.id, item.selling_plan])

    return (
        <div className="cart-item">
            <div className="row no-gutters align-items-center">
                <div className="col-4">
                    <div className="cart-item__img">
                        <span className="cart-item__badge"><i className={`fas fa-${icon}`}/></span>
                        <img src={product.images[0].originalSrc} width="1140" height="924" alt="" className="lazy"/>
                    </div>
                </div>
                <div className="col-8">
                    <div className="cart-item__content">
                        <div className="d-flex justify-content-between align-items-center">
                            <h6 className="txt--18 fw--medium mb--5"><span dangerouslySetInnerHTML={{ __html: title }}/></h6>
                            <button className="sidebar__close" onClick={() => removeLineItemInCart(item.id, item.selling_plan)}><i className="fal fa-times" aria-label="Close Sidebar."/></button>
                        </div>
                        <div className={`cart-item__price ${item.selling_plan !== 'No' ? 'mb--5' : 'mb--10' }`}>
                            <p className="txt--16 txt--pink"><strong>${(Math.round(item.price * 100) / 100).toFixed(2)}</strong></p>
                        </div>
                        {item.selling_plan !== 'No' &&
                        <span className="subscribed-label">{subscriptionMessage}</span>
                        }

                        {productData[item.id].type.toLowerCase() === 'supplements' &&
                        <div className="cart-item__subscribed">
                            <div className="input-field input-field--number">
                                <button
                                    className="step step--down"
                                    onClick={stepDown}
                                ><i className="fas fa-minus" aria-label="Minus"/></button>
                                <input
                                    type="number"
                                    value={count}
                                    min="0"
                                    onChange={e => setCount(+e.target.value)}
                                />
                                <button
                                    className="step step--up"
                                    onClick={stepUp}
                                ><i className="fas fa-plus" aria-label="Plus"/></button>
                            </div>
                        </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
