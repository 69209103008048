import React, {useEffect, useState} from "react";

const CountryPopup = ({handleChangeCountry, handleChangeCountryPopup}) => {

    const [country, setCountry] = useState('US');
    useEffect(()=>{
        setCountry(window.localStorage.getItem("country"))
    },[])

    return (
        <>
            <span className="overlay overlay-over-sidebar" onClick={handleChangeCountryPopup}/>
            <div className="popup-preheader shadow-1">
                <div className="popup-preheader__header arrow-down arrow-down--pink arrow-down--sm">
                    <img src="/images/globe-icon.png" width="53" height="60" alt="" className="radius-0 no-shadow"/><span>Shop Location Alert</span>
                    <span onClick={handleChangeCountryPopup} className="close-btn"><i className="fal fa-times"/></span>
                </div>

                <div className="popup-preheader__content">
                    <p>Be aware that when changing your shop location from your local address, some items may have limited availability.</p>
                    { country === "US" ?
                    <div>
                        <button onClick={()=>{handleChangeCountry("US")}} className="btn btn--md mr--10 mr--xxs-0 mb--xxs-10">Stay in US Shop</button>
                        <button onClick={()=>{handleChangeCountry("worldwide")}} className="btn btn--md btn--outline">Go to Worldwide Shop</button>
                    </div>
                    :
                    <div>
                        <button onClick={()=>{handleChangeCountry("worldwide")}} className="btn btn--md mr--10 mr--xxs-0 mb--xxs-10">Stay in Worldwide Shop</button>
                        <button onClick={()=>{handleChangeCountry("US")}} className="btn btn--md btn--outline">Go to US Shop</button>
                    </div>
                    }

                </div>
            </div>
        </>
    )
}
export default CountryPopup
