import React, {useEffect, useState} from "react"
import { Helmet } from "react-helmet"

import Header from "../components/Header"
import Preheader from "../components/Preheader"
import Sidebar from "../components/Sidebar"
import Footer from "../components/Footer"

import "../styles/scss/app.scss"
import testimonials from "../utilities/testimonials";
import CountryPopup from "./CountryPopup";

const Layout = ({ children, title, isLoader }) => {

    const [country, setCountry] = useState('US');
    const [changeCountryPopup, setChangeCountryPopup] = useState(false)
    const [isPreheader, setIsPreheader] = useState(true)

    useEffect(() => {
        setCountry(window.localStorage.getItem("country"))
    },[])

    useEffect(()=>{
        window.addEventListener('resize', checkPreheader)
        checkPreheader()
    })

    const checkPreheader = () => {
        if(window.innerWidth < 992) {
            setIsPreheader(false)
        } else {
            setIsPreheader(true)
        }
    }

    useEffect(()=>{
        if(!isLoader) {
            document.body.classList.remove('page-loader--overflow');
        }
    },[isLoader])

    useEffect(() => {
        testimonials()
        checkCountry()
    })



    const handleCountry = () => {
        setChangeCountryPopup(true)
    }
    const handleChangeCountry = (changedCountry) => {
        if (country === changedCountry) {
            setChangeCountryPopup(false)
        } else {
            window.localStorage.setItem('country', changedCountry)
            // window.localStorage.setItem('cartItems', []);
            setChangeCountryPopup(false)
            setCountry(changedCountry)
            window.location.reload();
        }
    }
    const handleChangeCountryPopup = () => {
        setChangeCountryPopup(false)
    }

    const checkCountry = () => {
        let country = (window.localStorage.getItem('country')) ? window.localStorage.getItem('country') : 'US';
        setCountry(country)
    }
    return (
        <>
            {/*head*/}
            <Helmet
                bodyAttributes={{className: isLoader ? "page-loader--overflow" : ''}}
            >
                <title>
                    {title ? title : 'The Betty Rocker'}
                </title>
                <link rel="preload" href="/fonts/oswald-v35-latin/oswald-v35-latin-regular.woff2" as="font" crossOrigin/>
                <link rel="preload" href="/fonts/oswald-v35-latin/oswald-v35-latin-700.woff2" as="font" crossOrigin/>
                <link rel="preload" href="/fonts/lato-v16-latin/lato-v16-latin-regular.woff2" as="font" crossOrigin/>
            </Helmet>

            {/*preheader*/}
            {isPreheader &&
            <Preheader
               handleCountry={handleCountry}
            />}

            {/*header*/}
            <Header
                handleCountry={handleCountry}
                isPreheader={isPreheader}
            />

            {/*sidebar*/}
            <Sidebar/>

            {/*content*/}
            <main>{children}</main>

            {/*footer*/}
            <Footer title={title} handleCountry={handleCountry}/>

            {changeCountryPopup &&
            <CountryPopup
                handleChangeCountry={handleChangeCountry}
                handleChangeCountryPopup={handleChangeCountryPopup}
            />
            }

            {isLoader &&
                <span className="page-loader"/>
            }
        </>
    )
}
export default Layout
