import React from "react";

import CartItem from "./CartItem";
import ThemeContext from "../context/ThemeContext";
import SidebarRecommended from "./SidebarRecommended";
import { UseShopifyDataQuery } from "../hooks/shopifyData";


const Sidebar = () => {

    const shopifyData = UseShopifyDataQuery();

    const updateId = (id) => {
        if(id.indexOf("Shopify__ProductVariant__") !== -1) {
            id = id.split("Shopify__ProductVariant__")[1]
        }
        return id
    }

    return (
        <ThemeContext.Consumer>
            {theme => (
                <>
                    { (theme.sidebarOpen) ? (
                        <span
                            role="button"
                            onKeyDown={theme.handleSidebar}
                            onClick={theme.handleSidebar}
                            tabIndex={0}
                            aria-label="Close Sidebar."
                            className="sidebar-overlay"/>
                    ) : ''}
                    <div className={`sidebar ${ theme.sidebarOpen ? 'open' : '' }`}>
                        { theme.sidebarOpen && theme.buttonLoader &&
                        <span className="loader"/>
                        }
                        <div className="sidebar__header">

                            <div className="d-flex justify-content-between align-items-center mb--30 mb--xs-15">
                                <h4 className="d-flex align-items-center txt--uppercase">
                                    <strong>your <span className="txt--pink">cart</span></strong>
                                    {theme.cart && theme.cart.length > 0 &&
                                    <span className="badge">{theme.cart.length}</span>}
                                </h4>
                                <button onClick={theme.handleSidebar} className="sidebar__close"><i className="fal fa-times" aria-label="Close Sidebar."/></button>
                            </div>

                        </div>
                        <div className="sidebar__content">
                            {theme.cart.length ?
                            <div className="sidebar__content__items">
                            {
                                theme.cart && theme.cart.map((item, index) => (
                                    shopifyData.map((product, index) => (
                                        <>
                                            { (item.id === updateId(product.variants[0].id) ) &&
                                            <CartItem
                                                key={index}
                                                product={product}
                                                item={item}
                                                removeLineItemInCart={theme.removeLineItemInCart}
                                                updateQuantityInCart={theme.updateQuantityInCart}
                                            />
                                            }
                                        </>
                                    ))
                                ))
                            }
                            </div>
                            :
                            <p className="txt--gray txt--15">There are no items added in the cart.</p>
                            }

                            {/*you might also like products*/}
                            {theme.cart.length ?
                                <SidebarRecommended
                                    checkout={theme.cart}
                                    shopifyData={shopifyData}
                                /> : ''
                            }
                        </div>
                        <div className="sidebar__footer">

                            <h5 className="d-flex justify-content-between align-items-center mb--20 txt--uppercase">
                                <span>subtotal</span><span className="txt--pink">${(Math.round(theme.total * 100) / 100).toFixed(2)}</span></h5>

                            <div className="d-flex justify-content-between align-items-center">
                                <button onClick={theme.handleSidebar} className="btn btn--outline">continue shopping</button>
                                {theme.cart.length ?
                                    <a href={theme.checkoutUrl} className="btn">checkout</a>
                                    :
                                    <button disabled className="btn">checkout</button>
                                }
                            </div>
                        </div>
                    </div>
                </>
            )}

        </ThemeContext.Consumer>
    )
}
export default Sidebar
